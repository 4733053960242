import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <header className="py-4 px-6 flex justify-between items-center border-b border-[#3d0066] relative z-50">
      <div className="flex items-center">
        <h1 className="text-2xl font-bold">Clone<span className="text-[#b366ff]">Wars</span>.io</h1>
      </div>
      <nav className="flex items-center space-x-8">
        <Link to="/" className={`text-sm font-medium ${location.pathname === '/' ? 'border-b-2 border-[#b366ff] pb-1' : ''}`}>HOME</Link>
        <Link to="/technical" className={`text-sm font-medium ${location.pathname === '/technical' ? 'border-b-2 border-[#b366ff] pb-1' : ''}`}>Clone debates arena</Link>
        <Link to="/business" className={`text-sm font-medium ${location.pathname === '/business' ? 'border-b-2 border-[#b366ff] pb-1' : ''}`}>Own your clone - DAO</Link>
        <button onClick={() => navigate('/contact')} className="bg-[#b366ff] text-white px-4 py-2 rounded-full text-sm font-medium">Propose a clone</button>
      </nav>
    </header>
  );
}

export default Header;