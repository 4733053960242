import React from 'react';

function BusinessServices() {
  return (
    <div className="container mx-auto px-4 py-16">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-center">
          <img alt="Laptop with AI chatbot icon" className="mx-auto mb-4" height="100" src="/assets/aicontent.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Own your clone
          </h3>
          <p className="text-sm text-gray-300">
            A clone is a trained AI with the intellectual content, the voice and the face of the character (transformed into an avatar figure).<br/>
            An AI clone has a unique NFT token. If you own it, you can broadcast any video with your clone on your channels prime.
          </p>
        </div>
        <div className="text-center">
          <img alt="Computer monitor with graph and gear icon" className="mx-auto mb-4" height="100" src="/assets/aibi.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Clone training
          </h3>
          <p className="text-sm text-gray-300">
            To own a clone, you need to buy its NFT at minting.<br/>
            The sales proceeds will pay the cost of the clone training. 
          </p>
        </div>
        <div className="text-center">
          <img alt="Cloud with database and network icons" className="mx-auto mb-4" height="100" src="/assets/aidata.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Define debates 
          </h3>
          <p className="text-sm text-gray-300">
            In the DAO, members define which debates should occur and what subjects to be handled<br/>
            They burn their CLW tokens to wieght in the votes.
          </p>
        </div>
        <div className="text-center">
          <img alt="Computer monitor with shopping cart icon" className="mx-auto mb-4" height="100" src="/assets/aiecommerce.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Propose a clone
          </h3>
          <p className="text-sm text-gray-300">
            CloneWars will mine in priority clones for which the most CLW (clonewars tokens) has been burned.
          </p>
        </div>
        <div className="text-center">
          <img alt="AI chip icon" className="mx-auto mb-4" height="100" src="/assets/aimarketing.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            Access to your clone AI
          </h3>
          <p className="text-sm text-gray-300">
            If you own the clone NFT, you will own the telegram channel dedicated to the clone (so that users can interact directly with the clone).<br/>
            You will also have an API access for text only interactions with your clone.
          </p>
        </div>
        <div className="text-center">
          <img alt="Plant growing from hand icon" className="mx-auto mb-4" height="100" src="/assets/aistartup.png" width="100"/>
          <h3 className="text-xl font-semibold mb-2">
            CLW tokens
          </h3>
          <p className="text-sm text-gray-300">
            CLW tokens are sold on a continuous manner (every month). There are utility tokens to help the community influence the directions of the DAO.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BusinessServices;