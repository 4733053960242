import React from 'react';

function TechnicalServices() {
  return (
    <main className="flex-grow container mx-auto px-4 py-8">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-white text-3xl font-light mb-2">organize your new</h2>
            <h1 className="text-[#ff6b35] text-6xl font-bold">DEBATE</h1>
          </div>
          <div className="w-1/2">
            <img alt="AI leading the path" width="200" height="200" src="/assets/aitubo.jpg" style={{objectFit: 'contain'}}/>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <h1 className="text-2xl md:text-3xl font-bold text-center mb-12">
       <center>The debate rules</center> 
      </h1>
      <div className="flex flex-col md:flex-row-reverse items-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:ml-8">
          <img alt="Another abstract AI visualization" className="w-full rounded-lg" height="150" src="/assets/server.png" width="240"/>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
            IA clones training
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-sm md:text-base leading-relaxed">
            <li><strong>Sources: </strong>  All youtube videos. All public articles and books mentionned by the authors. </li>
            <li><strong>Avatar:</strong>  Custom IA prompt to map the avatar with the author personality</li>
            <li><strong>Believes map:</strong>  List of the authors believes extracted from the youtube videos</li>
            <li><strong>late thinkers:</strong>  Trained on public book knowledge</li>
          </ul>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="mb-16 flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:mr-8">
          <img alt="Abstract visualization of AI" className="w-full rounded-lg" height="200" src="/assets/ABERKANE.png" width="300"/>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
           IA clone card
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-sm md:text-base leading-relaxed">
            <li><strong>Concepts mastery (%)</strong> Assess the mastery of intellectual concepts</li>
            <li><strong>Art of rhetorics (%)</strong> How well the character masters the debating skills</li>
            <li><strong>Major believes:</strong> Key believes of a character</li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse items-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:ml-8">
          <img alt="Another abstract AI visualization" className="w-full rounded-lg" height="200" src="/assets/debate.png" width="300"/>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-xl md:text-2xl font-semibold mb-4">
              Debate arena
          </h2>
          <ul className="list-disc pl-6 space-y-2 text-sm md:text-base leading-relaxed">
            <li><strong>Duration:</strong> 50 minutes</li>
            <li><strong>Support:</strong> Sound podcast and avatar talking on a video webinar.</li>
            <li><strong>Jury: </strong> An IA assess the debate winner using a) True and false statements b) Rhertoric strength c) Thinking coherence</li>
          </ul>
        </div>
      </div>
    </main>
  );
}

export default TechnicalServices;