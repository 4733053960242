import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-6 px-8 relative z-10">
      <div className="flex justify-between items-center">
        <div>
          <div className="mb-2">
            <Link to="/privacy-policy" className="mr-4 hover:underline">Privacy Policy</Link>
            <Link to="/cookie-policy" className="hover:underline">Cookies Policy</Link>
          </div>
          <p className="text-sm">contact@clonewars.io</p>
          <p className="text-sm mt-2">Copyright © 2024 - CLONEWARS</p>
        </div>
        <div className="flex space-x-4">
          <a href="https://x.com/Sebastien_Jehan" className="text-white hover:text-gray-300"><i className="fab fa-twitter"></i></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;