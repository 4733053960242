import React from 'react';

function MainContent() {
  return (
    <main className="flex-grow flex flex-col justify-center items-center px-4 py-16 relative z-10">
      <div className="container mx-auto px-4 py-12">
        <header className="text-center mb-12">
          <h1 className="text-2xl font-semibold mb-6">
            CloneWars, let your favorite influenceurs debate.
          </h1>
        </header>
        <section className="mb-16">
          <p className="text-center text-lg mb-8">
            Choose 2 youtubeurs you like and make their AI avatar debate on important subjects. 
          </p>
        </section>
        <section className="mb-16">
          <h2 className="text-center text-sm font-semibold uppercase tracking-wider mb-4">
            Our AI clones are trained with all public knowledge of "influenceurs"
          </h2>
          <div className="text-center">
            <h3 className="text-6xl font-bold">
              Confront &nbsp;
              <span className="text-[#ff00ff]">
                ideas
              </span>
            </h3>
          </div>
        </section>
        <section>
          <h2 className="text-center text-sm font-semibold uppercase tracking-wider mb-8">
            Current characters in the cloning queue list
          </h2>
          <div className="border-t border-gray-700 pt-8">
            <div className="flex justify-between items-center">
              <button className="text-2xl">
                <i className="fas fa-chevron-left"></i>
              </button>
              <div className="flex space-x-8 items-center">
                <img  className="h-24" src="/assets/CLONE1.png" />
                <img  className="h-24" src="/assets/CLONE2.png" />
                <img  className="h-24" src="/assets/CLONE3.png" />
                <img  className="h-24" src="/assets/CLONE4.png" />
                <img  className="h-24" src="/assets/CLONE5.png" />
                <img  className="h-24" src="/assets/CLONE6.png" />
                <img  className="h-24" src="/assets/CLONE7.png" />
                <img  className="h-24" src="/assets/CLONE8.png" />
                <img  className="h-24" src="/assets/CLONE9.png" />
                <img  className="h-24" src="/assets/CLONE10.png" />
              </div>
              <button className="text-2xl">
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default MainContent;