import React from 'react';

function CookiePolicy() {
  return (
    <div className="max-w-4xl mx-auto px-8 py-16">
      <h1 className="text-3xl font-bold mb-4">CloneWars Cookie Policy</h1>
      
      <p className="text-gray-400 mb-6">Effective Date: 06/10/2023</p>
      
      <p className="mb-6">This Cookie Policy explains how GPTCONSEIL uses cookies and similar technologies when you visit our website.</p>
      
      <ol className="list-decimal pl-6 space-y-4">
        <li className="font-semibold">What are Cookies?
          <p className="font-normal mt-2">Cookies are small text files stored on your computer or mobile device by a website. They are used to remember your actions and preferences over a period of time, making your subsequent visits more efficient and personalized.</p>
        </li>
        
        <li className="font-semibold">Types of Cookies We Use
          <ul className="list-[lower-alpha] pl-6 mt-2 space-y-2">
            <li className="font-normal"><span className="font-semibold">First-Party Cookies:</span><br />
            These are set by our website directly and are used to recognize you when you return to our site, enabling us to personalize our content for you and remember your preferences.</li>
            <li className="font-normal"><span className="font-semibold">Third-Party Cookies:</span><br />
            These cookies are set by a domain other than our website. We use third-party cookies for analytics purposes, such as Google Analytics, to understand how our website is being used and how we can improve your experience.</li>
          </ul>
        </li>
        
        <li className="font-semibold">How We Use Cookies
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li className="font-normal">To track your visit and improve your browsing experience (e.g., remembering your preferences and the pages you visited).</li>
            <li className="font-normal">For analytics to enhance website performance.</li>
            <li className="font-normal">To enable social media sharing and for marketing purposes if you have opted in for our marketing communications.</li>
          </ul>
        </li>
        
        <li className="font-semibold">No Sensitive Information Stored. We do not use cookies to store sensitive personal information like your address or account passwords.</li>
        
        <li className="font-semibold">Managing Cookies
          <ul className="list-disc pl-6 mt-2 space-y-2">
            <li className="font-normal">Disabling Cookies: You can disable cookies through your browser settings. However, disabling cookies may impact your experience on our site and limit some functionalities.</li>
            <li className="font-normal">Deleting Cookies: You can delete cookies stored on your device by clearing your browser's browsing history. This will remove all cookies from all websites you've visited.</li>
          </ul>
        </li>
        
        <li className="font-semibold">Your Consent. By using our website, you consent to our use of cookies as described in this policy.</li>
        
        <li className="font-semibold">Contact Us: For any queries regarding our Cookie Policy, please contact us at hello@zaai.ai.</li>
      </ol>
    </div>
  );
}

export default CookiePolicy;